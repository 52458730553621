@import 'https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Playfair+Display:400,400i,700,700i,900,900i';

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 80px;
    line-height: 70px;
}

h4 {
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    letter-spacing: 2px;
    position: relative;
    color: #425BB5;
    margin-left: 40px;
    font-weight: 700;
    /* font-size: 1vw; */
}

h4:before {
    position: absolute;
    width: 30px;
    left: -40px;
    content: "";
    display: block;
    height: 2px;
    background: #425BB5;
    top: 9px;
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: transparent;
}

#home {
    background: url(home-bg-2.jpg);
    background-size: cover;
    height: 100vh;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

.disply-table {
    display: table;
    height: 100vh;
}

/* **********************************preloader**************************************** */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 10000;
    background-image: url(giphy.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;
    height: 100vh;
}


/* **********************************navbar***************************************** */
header {
    text-align: center;
    -webkit-transition: all .5s;
    transition: all .5s;
    height: 65px;
}

.nav-link:focus {
    color: #444;
    text-decoration: none;
}

.nav-link:hover {
    color: #425BB5;
    text-decoration: none;
}

.navbar-fixed-top {
    position: fixed;
    width: 98vw;
    z-index: 2;
}

@media only screen and (min-width:991px) {
    .collapse {
        position: relative;
        left: 38vw;
        top: 1vw;
    }

    .navbar.active {
        background-color: white;
        transition: all .5s;
        width: 100%;
        border-bottom: 2px solid black;
    }

    .navbar.active .nav-link {
        transition: width 2s linear 1s;

    }

    .navbar-fixed-top {
        position: fixed;
        overflow: hidden;
        z-index: 2;
    }
}

@media only screen and (min-width:1200px) {
    .collapse {
        position: relative;
        left: 38vw;
        top: 1vw;
    }

    .navbar.active {
        background-color: white;
        width: 100%;
        transition: all .5s;
        border-bottom: 2px solid black;

    }

    .navbar.active .nav-link {
        position: relative;
        transform: translateY(-1vw);
        text-decoration: underline;
        transition: transform .5s ease-in-out;
    }

    .navbar-fixed-top {
        position: fixed;
        overflow: hidden;
        z-index: 2;
    }

    .table-cell {
        position: relative;
        top: 10px;
        left: 5px;
    }
}

.navbar.active {
    background-color: white;
    transition: all .5s;
    width: 100%;
    border-bottom: 2px solid black;
}

.navbar.active .nav-link {
    transition: width 2s linear 1s;

}

/* .navbar-fixed-top{
    position: fixed;
    z-index: 2;
} */
/* ********************************about********************************************* */

#about {
    padding: 7vw;
    z-index: 1;
}

.back-img {
    width: 25vw;
    height: 30vw;
}

#about .container {
    display: flex;
}

.size-50 {
    font-size: 50px;
    line-height: 50px
}

.hh-50 {
    height: 50px;
}

/* **********************************Experince*********************************************** */

#experience {
    padding: 100px 0;
    background: #f3f3f3
}

.experience-row {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}

.experience-row h3 {
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: #425BB5;
    font-size: 16px;
    font-weight: bold
}

.experience-row p {
    color: #888;
    margin-top: 20px;
    font-size: 15px;
}

.experience-row .date {
    color: #000;
    font-size: 17px;
}

.timeline {
    padding-left: 0;
    list-style: none;
    position: relative;
}

.timeline:before {
    background-color: black;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
}

.timeline-event {
    position: relative;
}

.timeline-event:hover .timeline-event-icon {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #425BB5;
}

.timeline-event:hover .timeline-event-thumbnail {
    box-shadow: inset 40em 0 0 0 #425BB5;
}

.timeline-event-copy {
    padding: 2em;
    position: relative;
    top: -1.875em;
    left: 4em;
    width: 100%;
}

.timeline-event-copy h3 {
    font-size: 1.75em;
}

.timeline-event-copy h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
}

.timeline-event-copy strong {
    font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
    padding-bottom: 1.2em;
}

.timeline-event-icon {
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #425BB5;
    outline: 10px solid #f3f3f3;
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 2em;
    width: 1em;
    height: 1em;
}

.timeline-event-thumbnail {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: white;
    font-size: 12px;
    font-weight: bold;
    background-color: black;
    box-shadow: inset 0 0 0 0em #425BB5;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
}


/* ************************************projects****************************************** */

#projects {
    padding-top: 6vw;
    padding-bottom: 6vw;
    z-index: 1;
}

#projects .container {
    position: relative;
}

.portfolio .categories-grid span {
    font-size: 30px;
    margin-bottom: 1vw;
    display: inline-block;
}

.portfolio .categories-grid .categories ul li {
    list-style: none;
    margin: 1vw 0;
}

.portfolio .categories-grid .categories ul li a {
    display: inline-block;
    color: #60606e;
    padding: 0 1vw;
    margin: 0 1vw;
    -webkit-transition: all .2s ease-in-out .2s;
    transition: all .2s ease-in-out .2s;
}

.portfolio .categories-grid .categories ul li a:hover,
.portfolio .categories-grid .categories ul li a:focus {
    text-decoration: none;
}

.portfolio .categories-grid .categories ul li a.active {
    background-color: #425BB5;
    padding: 0px 1vw;
    color: white;
    text-decoration: none;
}

.portfolio_filter {
    padding-left: 0;
}

.portfolio_item {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 2.5vw;
}

.portfolio_item .portfolio_item_hover {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #425BB5;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #333;
}

.portfolio_item .portfolio_item_hover .item_info {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 1vw;
    width: 100%;
    font-weight: bold;
}

.portfolio_item .portfolio_item_hover .item_info span {
    display: block;
    color: #fff;
    font-size: 18px;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
    opacity: 0;
}

.portfolio_item .portfolio_item_hover .item_info em {
    font-style: normal;
    display: inline-block;
    background-color: #425BB5;
    padding: 0.5vw 1vw;
    color: #fff;
    margin-top: 1vw;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
    opacity: 0;
    font-size: 10px;
    letter-spacing: 2px;
}

.portfolio_item:hover .portfolio_item_hover {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.portfolio_item:hover .item_info em,
.portfolio_item:hover .item_info span {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.portfolio .categories-grid .categories ul li {
    float: left;
}

.portfolio .categories-grid .categories ul li a {
    padding: 0 1vw;
    -webkit-transition: all .2s ease-in-out .2s;
    transition: all .2s ease-in-out .2s;
}

.portfolio_filter {
    padding-left: 0;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}
.portfolio-padding {
    padding: 2vw 0;
}

.portfolio-inner {
    padding-bottom: 0;
    padding-top: 2vw;
}

.modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    border: none;
}

.popup-modal {
    background: #fff;
}

.close-popup-modal {
    float: right;
    margin: 20px;
    font-size: 22px;
    opacity: 0;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
    position: fixed;
    z-index: 100000;
    right: 14px;
}

.animatedModal-on .close-popup-modal {
    opacity: 1;
}

.modal-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    line-height: 2.5vw;
}

#projects .img-responsive {
    height: 15vw;
    display: inline;
}

/* *********************************************Education**************************************** */
#Education {
    padding-top: 6vw;
    padding-bottom: 6vw;
}

#Education .container {
    position: relative;
    /* left: 4vw; */
}

#Education .container .title {
    position: relative;
    /* left: -6vw; */
}

#Education ion-icon {
    font-size: 80px;
    font: sans-serif;
    position: relative;
    left: 3vw;
    margin-bottom: 4vw;
}

#Education .card {
    /* width: auto; */
    /* background-image: linear-gradient(#9dab9ec1,#9dab9ec1,#233161a5); */
    border: none;
    color: black;
}

/* ************************************************Skills****************************************** */

#Skills {
    padding-top: 6vw;
    padding-bottom: 6vw;
    background: #f3f3f3;
}

#Skills ion-icon {
    font-size: 70px;
    font: sans-serif;
    position: relative;
    left: 3vw;
    margin: 4vw;
}

.services .icon-box {
    text-align: center;
    padding: 70px 20px 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
  }
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    position: relative;
  }
  
  .services .icon-box .icon i {
    font-size: 36px;
    transition: 0.5s;
    position: relative;
  }
  
  .services .icon-box .icon svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .services .icon-box .icon svg path {
    transition: 0.5s;
    fill: #f5f5f5;
  }
  
  .services .icon-box h5 {
    font-weight: 600;
    margin: 10px 0 15px 0;
    font-size: 22px;
  }
  
  .services .icon-box h5 a {
    color: #45505b;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
  }
  
  .services .iconbox-blue i {
    color: #47aeff;
  }
  
  .services .iconbox-blue:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-blue:hover .icon path {
    fill: #47aeff;
  }
  
  .services .iconbox-orange i {
    color: #aa724c;
  }
  
  .services .iconbox-orange:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-orange:hover .icon path {
    fill: #ffa76e;
  }
  
  .services .iconbox-pink i {
    color: #e80368;
  }
  
  .services .iconbox-pink:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-pink:hover .icon path {
    fill: #e80368;
  }
  
  .services .iconbox-yellow i {
    color: #ffbb2c;
  }
  
  .services .iconbox-yellow:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-yellow:hover .icon path {
    fill: #ffbb2c;
  }
  
  .services .iconbox-red i {
    color: #ff5828;
  }
  
  .services .iconbox-red:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-red:hover .icon path {
    fill: #ff5828;
  }
  
  .services .iconbox-teal i {
    color: #11dbcf;
  }
  
  .services .iconbox-teal:hover .icon i {
    color: #fff;
  }
  
  .services .iconbox-teal:hover .icon path {
    fill: #11dbcf;
  }
/* ***************************************media queries****************************************** */

@media (max-width: 991px) {
    .portfolio .categories-grid .categories ul li a {
        padding: 0 2px;
    }

    .navbar-nav {
        --bs-nav-link-padding-x: 0;
        --bs-nav-link-padding-y: 0;
        --bs-nav-link-color: var(--bs-navbar-color);
        --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
        --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
}

@media (max-width: 768px) {
    .timeline-event-copy {
        width: 90%;
        left: 2em;
    }

    #navbar [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
        /* position: fixed; */
        left: 83vw;
    }
}

@media (max-width: 500px) {
    h1 {
        font-family: 'Playfair Display', serif;
        font-size: 50px;
        line-height: 50px;
    }

    #home,
    .disply-table {
        height: 600px;
    }

    .about-border {
        border: 3px solid #425bb576;
        width: 265px;
    }

    .portfolio .categories-grid .categories ul li {
        list-style: none;
        margin: 10px 0;
    }

    /* #Education .card{
        width: 55vw;
    } */
}

@media (max-width: 420px) {
    nav li {
        margin: 6px;
    }

    nav li a {
        font-size: 10px;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 24px;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #0563bb;
    float: left;
    width: 44px;
    height: 44px;
    background: #eef7ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h5 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #45505b;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #728394;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #0563bb;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #0563bb;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #0678e3;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #f7f8f9;
    color: #45505b;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
  }
  
  #footer h3 {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0 0 15px 0;
  }
  
  #footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }
  
  #footer .social-links {
    margin: 0 0 40px 0;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #0563bb;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #0678e3;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .copyright {
    margin: 0 0 5px 0;
  }
  
  #footer .credits {
    font-size: 13px;
  }